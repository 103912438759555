import React, { useEffect } from 'react';
import { pageTitle } from '../../helper';
import Cta from '../Cta';
import FunFact from '../FunFact';
import PageHeading from '../PageHeading';
import Div from '../Div';
import SectionHeading from '../SectionHeading';
import TeamSlider from '../Slider/TeamSlider';
import Spacing from '../Spacing';
import { funfaceData } from '../../data/funfaceData';
import EquipmentTable from '../EquipmentTable';
import { updateMetaTags } from '../../helper/updateMetaTags';

export default function AboutPage() {
  pageTitle('O nás');

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <div>
        {updateMetaTags("Seznamte se s týmem Stable Records Studio. Naše zkušenosti a profesionální přístup zajišťují špičkové výsledky pro každého klienta.", "o nás, tým nahrávacího studia, zkušenosti, profesionální přístup, Stable Records")}
    
      </div>
      {/* Start Page Heading Section */}
      <PageHeading
        title="O nás"
        bgSrc="images/about_hero_bg.jpeg"
        pageLinkText="O nás"
      />
      {/* End Page Heading Section */}

      {/* Start About Section */}
      <Spacing lg="150" md="80" />
      <Div className="container">
        <Div className="row">
          <Div className="col-xl-7 col-lg-7">
            <SectionHeading
              title="Místo kde plníme vaše hudební sny"
              subtitle="O stable records"
            >
              <Spacing lg="30" md="20" />
              <p className="cs-m0">

<p>Jmenuji se Jan Maštalíř a jsem aktivní muzikant (kytara, zpěv) v kapelách <a className='cs-link' href='https://www.youtube.com/@Tcheichan'>Tcheichan</a> a <a className='cs-link' href='https://www.youtube.com/@kapelamanu8198'>Manu</a>. Nahrávání a produkci hudby se věnuji již více než dvacet let. V lednu 2022 jsem založil hudební a nahrávací studio Stable Records.</p>
<p> 
Od dětství jsem snil o provozování hudebního studia a pomáhání muzikantům v plnění jejich snů. Ve Stable Records se zaměřujeme na spolupráci a tvorbu nových písní. Nabízíme kompletní služby od kompozice, nahrávání, mixu až po mastering. Kromě toho mohu zprostředkovat distribuci na streamovací platformy jako Spotify, Apple Music a další. Pokud si chcete produkci nebo mix dělat sami, rád vám pomůžu klidně jen s částí vašeho projektu, například s nahráváním.</p>
<p>
Jsem duší muzikant, ale hlavou technik. Miluju hraní a aranžování hudby, zároveň mě baví zkoumat různé techniky používané při zpracování audia v mixu a masteringu. Moje studio se neustále rozšiřuje o nové, zajímavé hardwarové kousky (mikrofony, preampy, kytarové zesilovače a efekty) a spoustu různých SW pluginů, které jsou vám k dispozici pro vaše projekty.</p>
<p>
Ve Stable Records jsem odhodlán poskytovat profesionální a inspirativní prostředí pro všechny vaše nahrávací potřeby. A pokud vás to zajímá, "Maštal" se anglicky řekne "Stable", což inspirovalo název mého studia a logo s koněm a podkovou.
              </p>
              </p>
              <Spacing lg="30" md="30" />
              <Div className="cs-separator cs-accent_bg"></Div>
              <Spacing lg="25" md="40" />
            </SectionHeading>
          </Div>
          <Div className="col-lg-3 offset-xl-2">
            <img
              src="/images/stablerecords/mastix.jpg"
              alt="About"
              className="w-100 cs-radius_15"
            />
            <Spacing lg="25" md="25" />
          </Div>
          
        </Div>
      </Div>
      <Spacing lg="75" md="55" />
      {/* End About Section */}

      {/* Start Fun Fact Section */}
      <Div className="container">
      <FunFact
          variant="cs-type1"
          title="Základní informace v číslech"
          subtitle="Něco málo o nás v číslech"
          data={funfaceData}
        />
      </Div>
      <Spacing lg="100" md="80" />
      <Div className="container">
        <Div className="row">
        <Div className="cs-image_layer cs-style1">
          <EquipmentTable />
        </Div>
        </Div>
      </Div>
      {/* End Fun Fact Section */}

      {/* Start Why Choose Section */}
      {/* <Spacing lg="100" md="80" />
      <Div className="container">
        <Div className="row">
          <Div className="col-xl-5 col-lg-6">
            <Div className="cs-image_layer cs-style1">
              <Div className="cs-image_layer_in">
                <img
                  src="/images/about_img_4.jpeg"
                  alt="About"
                  className="w-100 cs-radius_15"
                />
              </Div>
            </Div>
            <Spacing lg="0" md="40" />
          </Div>
          <Div className="col-xl-5 offset-xl-1 col-lg-6">
            <SectionHeading
              title="Highly experienced pepole with us"
              subtitle="Why Choose Us"
            >
              <Spacing lg="30" md="20" />
              <p className="cs-m0">
                This is the main factor that sets us apart from our competition
                and allows us to deliver a specialist business consultancy
                service. Our team applies its wide-ranging experience to
                determining. Through our years of experience, we’ve also learned
                that while each channel.
              </p>
              <Spacing lg="15" md="15" />
              <p className="cs-m0">
                This is the main factor that sets us apart from our competition
                and allows us to deliver a specialist business consultancy
                service. Our team applies its wide-ranging experience to
                determining.
              </p>
              <Spacing lg="30" md="30" />
              <Div className="cs-separator cs-accent_bg"></Div>
              <Spacing lg="25" md="0" />
            </SectionHeading>
          </Div>
        </Div>
      </Div> */}
      {/* End Why Choose Section */}

      {/* Start Team Section */}
      {/* <Spacing lg="145" md="80" />
      <Div className="container">
        <SectionHeading
          title="Awesome team <br/>members"
          subtitle="Our Team"
          variant="cs-style1"
        />
        <Spacing lg="85" md="45" />
        <TeamSlider />
      </Div> */}
      {/* End Team Section */}

      {/* Start CTA Section */}
      <Spacing lg="150" md="80" />
      <Div className="container">
        <Cta
          title="Pojďme probrat <br />vaše představy"
          btnText="Napište nám"
          btnLink="/contact"
          bgSrc="/images/cta_bg.jpeg"
        />
      </Div>
      {/* End CTA Section */}
    </>
  );
}
