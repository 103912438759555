const servicesData = [
    {
      id: "accommodation",
      name: 'Ubytování',
      headline: 'Pohodlné Ubytování Přímo ve Studiu – V Ceně!',
      description: 'Nabízíme pohodlné ubytování přímo v prostorách našeho nahrávacího studia, a to bez jakýchkoli dalších poplatků. Naše ubytovací kapacity pojmou až 7 osob. Pokoje jsou vybaveny vším potřebným pro váš komfortní pobyt, abyste se mohli plně soustředit na svou kreativní práci. K dispozici je také venkovní prostor na grilování a večerní posezení, stejně jako bazén pro osvěžení po dlouhém dni ve studiu.',
      price: 'Cena na vyžádání',
      availability: 'Dostupné na vyžádání',
      images: [
        '/images/ubytko/grill.jpeg',
        '/images/ubytko/postele.jpg', 
        '/images/ubytko/koupelna.jpg',
        '/images/ubytko/bar.jpeg',
      ],
      bullets: [
        "Ubytování pro až 7 osob",
        "Vybavené pokoje s veškerým komfortem",
        "Možnost venkovního grilování",
        "Bazén",
        "All-inclusive - je možné zajistit i stravování",
        "Soukromí a klid",
        "Ideální pro kapely a hudební týmy",
        "Přímo ve studiu, bez nutnosti cestovat",
      ],
      src: '/images/ubytko/ubytko.jpg', // předpokládaná cesta k obrázku Ubytování
      // další atributy pro Ubytování
    },
    {
      id: "mix_mastering",
      name: 'Mix a Mastering',
      headline: 'Profesionální Mix a Mastering',
      description: 'V našem studiu se zaměřujeme na poskytování profesionálních služeb mixu a masteringu s důrazem na detail a kvalitu. Mix je proces, kdy se jednotlivé zvukové stopy sladí a upraví tak, aby vytvořily vyvážený a harmonický celek. Mastering je poslední krok, kde se finální mix upraví pro konzistentní a profesionální zvuk na různých médiích. <br> Před samotným mixem dbáme na to, aby byly všechny stopy pečlivě vyčištěné a aby timingově i tonově seděly, což je klíčové pro dosažení špičkového zvuku. Pracujeme s DAW Logic a využíváme širokou paletu špičkových 3rd party pluginů jako SSL, Universal Audio a Slate Digital. Pro mastering používáme kombinaci nástrojů Logic a externích pluginů, jako jsou Izotope Ozone 10 a Slate Digital FGX 2. Mixu a masteringu se věnujeme už více než deset let, což nám umožňuje dosáhnout profesionálního zvuku, na který se můžete spolehnout.',
      price: 'Cena na vyžádání',
      availability: 'Dostupné na vyžádání',
      images: [
         // předpokládaná cesta k obrázku Recording
        
         '/images/services/drums.jpeg',
         '/images/services/mixnmaster.jpeg',
        '/images/services/mix.jpg',
        '/images/services/mix2.jpeg'
      ],
      bullets: [
        "Precizní editace a mix zvukových stop",
        "Práce v DAW Logic",
        "Špičkové pluginy (Universal Audio, Slate Digital, SSL)",
        "Masteringové nástroje (Izotope Ozone 10, Slate Digital FGX 2)",
        "Profesionální zvuk připraven pro streamovací platformy i fyzická média",
        "Více než 10 let zkušeností s mixem a masteringem",
        "Důraz na detail a kvalitu",
        "Spolupráce s klientem pro dosažení požadovaného zvuku",
      ],
      src: '/images/services/mix.jpg', // předpokládaná cesta k obrázku Mix a Mastering
      // další atributy pro Mix a Mastering
    },
    {
      id: "recording",
      name: 'Recording',
      headline: 'Dokonalý zvuk začíná už při náběru',
      description: 'Věříme, že základem dobré nahrávky je náběr. Proto u nás ve studiu najdete špičkové vybavení ať už jde o mikrofony, preampy nebo konvertory. Žijeme ve 21. století a proto si bereme to nejlepší z analog a digital světa. Naši výbavu si můžete prohlédnout zde.',
      price: 'Cena na vyžádání',
      availability: 'Dostupné na vyžádání',
      images: [
        '/images/services/recording.png', // předpokládaná cesta k obrázku Recording
        '/images/services/drums.jpeg',
        '/images/services/pavelhrala.jpeg',
        '/images/services/coffeecab.jpeg'
      ],
      bullets: [
        "Špičkové mikrofony (např. Neumann, Shure, AKG)",
        "Vysoce kvalitní preampy a kompresory (např. Universal Audio, Avalon)",
        "Moderní digitální pracovní stanice (DAW) jako Pro Tools, Logic Pro, Ableton Live",
        "Možnost nahrávání jednotlivých stop i živé kapely",
        "Multi-track recording pro maximální flexibilitu při mixu",
        "Monitoring v reálném čase pro okamžitou zpětnou vazbu",
        "Pomoc s aranžmá a přípravou materiálu",
        "Poradenství ohledně výběru nástrojů a technik nahrávání",
      ],
      src: '/images/services/recording.png', // předpokládaná cesta k obrázku Recording
      // další atributy pro Recording
    },
    {
      id: "studiorent",
      name: 'Pronájem Studia',
      headline: 'Pronájem Nahrávacího Studia pro Vaše Projekty',
      description: 'Nabízíme možnost pronájmu našeho komplet vybaveného nahrávacího studia pro vaše vlastní projekty. Studio je k dispozici včetně veškerého vybavení, což je ideální pro producenty nebo kapely, kteří nemají své vlastní studio a chtějí využít prostor třeba jen pro náběry. Pronájem je možný jak na hodiny, tak na celé dny, dle vašich potřeb. Studio je vhodné nejen pro nahrávání hudby, ale také například pro nahrávání podcastů a jiných audio projektů.',
      price: 'Cena na vyžádání',
      availability: 'Dostupné na vyžádání',
      images: [
        '/images/services/mixnmaster.jpeg', // předpokládaná cesta k obrázku Recording
        '/images/services/mix2.jpeg',
        '/images/services/fender.jpeg',
        '/images/services/coffeecab.jpeg'
      ],
      bullets: [
        "Kompletní vybavení: Studio je vybaveno špičkovými mikrofony, preampy a další technikou.",
        "Flexibilní pronájem: Možnost pronájmu na hodiny nebo na celé dny dle vašich potřeb.",
        "Ideální pro producenty bez vlastního studia",
        "Komfortní a inspirativní prostředí pro vaši kreativní práci.kapely",
        "Možnost asistence na místě pro technické otázky",
        "Akusticky upravené prostory pro optimální zvuk",
        "Možnost nahrávání podcastů a jiných audio projektů",


      ],
      src: '/images/services/studio.png', // předpokládaná cesta k obrázku Pronájem studia
      // další atributy pro Pronájem studia
    },
  ];
  
  export default servicesData;